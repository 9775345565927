import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';
import { authGuard } from './guards/auth.guard';
import { ProductsComponent as AdminProductsComponent } from './pages/admin/products/products.component';
import { TransactionsComponent as AdminTransactionComponent } from './pages/admin/transactions/transactions.component';
import { OrdersComponent as AdminOrderComponent } from './pages/admin/orders/orders.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { adminGuard } from './guards/admin.guard';
import { unauthGuard } from './guards/unauth.guard';

export const routes: Routes = [
  {
    path: '',
    component: PageLayoutComponent,
    children: [
      {
        path: 'home',
        canActivate: [unauthGuard],
        loadComponent: () =>
          import('./pages/home/home.component').then((m) => m.HomeComponent),
      },
      {
        path: 'login',
        loadComponent: () =>
          import('./pages/others/login/login.component').then(
            (m) => m.LoginComponent
          ),
      },
      {
        path: 'product',
        // canActivate: [authGuard],
        loadComponent: () =>
          import('./pages/product/products/products.component').then(
            (m) => m.ProductsComponent
          ),
      },
      {
        path: 'product/:id',
        loadComponent: () =>
          import(
            './pages/product/product-detail/product-detail.component'
          ).then((m) => m.ProductDetailComponent),
      },
      {
        path: 'buy-gold',
        canActivate: [authGuard],
        loadComponent: () =>
          import('./pages/buy-gold/buy-gold.component').then(
            (m) => m.BuyGoldComponent
          ),
      },
      // {
      //   path: 'sell-gold',
      //   loadComponent: () =>
      //     import('./pages/sell-gold/sell-gold.component').then(
      //       (m) => m.SellGoldComponent
      //     ),
      // },
      {
        path: 'sip',
        canActivate: [authGuard],
        loadComponent: () =>
          import('./pages/sip/sip.component').then((m) => m.SipComponent),
      },
      {
        path: 'checkout',
        canActivate: [authGuard],
        loadComponent: () =>
          import('./pages/checkout/checkout.component').then(
            (m) => m.CheckoutComponent
          ),
      },
      {
        path: 'contact-us',
        loadComponent: () =>
          import('./pages/others/contact-us/contact-us.component').then(
            (m) => m.ContactUsComponent
          ),
      },
      {
        path: 'info/faq',
        loadComponent: () =>
          import('./pages/faq/faq.component').then((m) => m.FaqComponent),
      },
      {
        path: 'info/:info',
        loadComponent: () =>
          import('./pages/info/info.component').then((m) => m.InfoComponent),
      },
      {
        path: 'user/profile',
        loadComponent: () =>
          import('./pages/user/profile-view/profile-view.component').then(
            (m) => m.ProfileViewComponent
          ),
      },
      {
        path: 'user/dashboard',
        loadComponent: () =>
          import('./pages/user/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          ),
      },
      {
        path: 'error',
        loadComponent: () =>
          import('./pages/others/error/error.component').then(
            (m) => m.ErrorComponent
          ),
      },
      {
        path: 'transaction/status',
        loadComponent: () =>
          import('./pages/others/page-status/page-status.component').then(
            (m) => m.PageStatusComponent
          ),
      },
      {
        path: 'kyc/pan',
        loadComponent: () =>
          import('./pages/pan-verify/pan-verify.component').then(
            (m) => m.PanVerifyComponent
          ),
      },
      {
        path: 'transactions',
        loadComponent: () =>
          import('./pages/transaction/transaction.component').then(
            (m) => m.TransactionComponent
          ),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
    ],
  },
  {
    path: 'admin',
    canActivate: [adminGuard],
    component: AdminLayoutComponent,
    children: [
      {
        path: 'products',
        component: AdminProductsComponent,
      },
      {
        path: 'transactions',
        component: AdminTransactionComponent,
      },
      {
        path: 'orders',
        component: AdminOrderComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];
