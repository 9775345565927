<!-- Secondary Header -->
<p-toast />
<header id="secondary-header">
  <div><strong>Helpline:</strong> {{ helplineNo() }}</div>
  <div class="flex-auto"></div>
  <p-button
    [text]="true"
    severity="contrast"
    size="small"
    label="My Wishlist"
  ></p-button>
  @if(isVisible()){
  <p-button
    [text]="true"
    severity="contrast"
    size="small"
    label="My Profile"
    (onClick)="navigationToProfile()"
  ></p-button>
  } @else{
  <p-button
    [text]="true"
    severity="contrast"
    size="small"
    label="Login / SignUp"
    (onClick)="showDialog()"
  ></p-button>
  }
  <!-- <p-button [text]="true" severity="contrast" size="small" label="Sign Up" (onClick)="showDialog()" ></p-button>} -->
</header>

<!-- Primary Header -->
<header id="primary-header">
  <img
    src="assets/smccoinslogo.png"
    alt="smc coins logo"
    id="logo"
    [routerLink]="authenticateRoute()"
  />
  <div class="flex-auto"></div>
  <nav>
    <button
      pButton
      [text]="true"
      severity="contrast"
      [routerLink]="authenticateRoute()"
    >
      Home
    </button>
    <button pButton [text]="true" severity="contrast" routerLink="/product">
      Products
    </button>
    <button pButton [text]="true" severity="contrast" routerLink="/buy-gold">
      Buy Gold
    </button>
    <button pButton [text]="true" severity="contrast" routerLink="/sell-gold">
      Sell Gold
    </button>
    <button pButton [text]="true" severity="contrast">SIP</button>
    <button pButton [text]="true" severity="contrast">Information</button>
    <button pButton [text]="true" severity="contrast">Contact Us</button>
  </nav>
  <div class="flex-auto"></div>
  <p-button
    (onClick)="cartOverlayPanel.show($event)"
    icon="pi pi-shopping-cart"
    label="Cart"
    [badge]="cartAmount().toString()"
  ></p-button>
  <p-overlayPanel #cartOverlayPanel>
    <div id="cart-overlay" style="width: 256px">
      <h3>Recently Added Items <p-badge [value]="cartAmount()"></p-badge></h3>
      <div class="actions">
        <p-button
          label="View Cart"
          styleClass="w-full"
          icon="pi pi-shopping-cart"
        ></p-button>
        <p-button
          label="Checkout"
          styleClass="w-full"
          icon="pi pi-check-circle"
        ></p-button>
      </div>
    </div>
  </p-overlayPanel>
</header>

<!-- Body -->
<article>
  <ng-content></ng-content>
</article>

<!-- Primary Footer -->
<footer id="primary-footer">
  <section class="contact-us">
    <h3>Contact Us</h3>
    <div>
      <div class="row">
        <div class="pi pi-map-marker"></div>
        <div class="content">{{ location() }}</div>
      </div>
      <div class="row">
        <div class="pi pi-phone"></div>
        <div class="content">
          <a [href]="contactNoLink()">{{ contactNo() }}</a>
        </div>
      </div>
      <div class="row">
        <div class="pi pi-envelope"></div>
        <div class="content">
          <a [href]="contactMailLink()">{{ contactMail() }}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="information">
    <h3>Information</h3>
    <ul>
      <li><a href="info/about-us">About Us</a></li>
      <li><a href="info/privacy-policy">Privacy Policy</a></li>
      <li><a href="info/terms-of-use">Terms Of Use</a></li>
      <li><a href="info/disclaimer">Disclaimer</a></li>
      <li><a href="info/shipping-policy">Shipping Policy</a></li>
    </ul>
  </section>

  <section class="customer-service">
    <h3>Customer Service</h3>
    <ul>
      <li><a href="forms/contact-us">Contact Us</a></li>
      <li><a href="forms/complaints">Complaints</a></li>
      <li><a href="info/faq">FAQ</a></li>
      <li><a href="info/feedback">Feedback</a></li>
    </ul>
  </section>

  <section class="account">
    <h3>Account</h3>
    <ul>
      <li><a href="users/wallet">My Wallet</a></li>
      <li><a href="users/orders">Order History</a></li>
      <li><a href="users/wishlist">Wishlist</a></li>
      <li><a href="fomrs/newsletter">Newsletter</a></li>
    </ul>
  </section>
</footer>

<footer id="newsletter-footer">
  <fa-icon [icon]="icons.faEnvelopeOpen" size="xl" class="mr-3"></fa-icon>
  <h3>Newsletter</h3>
  <div class="newsletter-text">Subscribe to notification about our store</div>
  <form>
    <p-inputGroup>
      <input
        type="email"
        pInputText
        placeholder="Enter your email address ..."
      />
      <button type="submit" pButton icon="pi pi-angle-right"></button>
    </p-inputGroup>
  </form>
</footer>

<footer id="bottom-footer">
  <div class="copy">&copy; Pulin Comtrade Limited</div>
  <div class="flex-auto"></div>
  <div class="social-handles">
    <a target="_blank" href="https://www.facebook.com/SMCCoins?mibextid=LQQJ4d"
      ><img src="assets/social/facebook-logo.webp" alt="facebook"
    /></a>
    <a
      target="_blank"
      href="https://www.instagram.com/smccoins?igsh=MWJqbzcxZTloNTRzeQ=="
      ><img src="assets/social/instagram-logo.avif" alt="instagram"
    /></a>
    <!-- <a target="_blank" href="#"><img src="social/linkedin-logo.png" alt="linkedin"></a> -->
    <!-- <a target="_blank" href="https://x.com/smc_coins?s=11"><img src="social/twitter-logo.png" alt="twitter"></a> -->
    <a target="_blank" href="https://x.com/smc_coins?s=11"
      ><img src="assets/social/x-logo.avif" alt="x"
    /></a>
  </div>
</footer>

<!-- dialog -->
