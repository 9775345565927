<!-- Secondary Header -->
<p-toast />
<!-- <header id="secondary-header"> -->
<!-- <div><strong>Helpline:</strong> {{ helplineNo() }}</div>
  <div class="flex-auto"></div>
  @if (isAdmin()) {
  <p-button
    [text]="true"
    severity="contrast"
    size="small"
    label="Admin Panel"
    routerLink="/admin/transactions"
  ></p-button>
  } -->
<!-- <p-button
    [text]="true"
    severity="contrast"
    size="small"
    label="My Wishlist"
  ></p-button>
  -->
<!-- @if(isVisible()){
  <p-button
    [text]="true"
    severity="contrast"
    size="small"
    label="View Transaction"
    [routerLink]="['transactions']"
  ></p-button>
  <p-button
    [text]="true"
    severity="contrast"
    size="small"
    label="My Profile"
    (onClick)="navigationToProfile()"
  ></p-button>
  <p-button
    [text]="true"
    severity="contrast"
    size="small"
    label="Sign Out"
    (onClick)="signOutUser()"
  ></p-button>
  } @else{
  <p-button
    [text]="true"
    severity="contrast"
    size="small"
    label="Login / SignUp"
    (onClick)="showDialog()"
  ></p-button>
  } -->
<!-- <p-button [text]="true" severity="contrast" size="small" label="Sign Up" (onClick)="showDialog()" ></p-button>} -->
<!-- </header> -->

<!-- Primary Header -->
<header id="primary-header">
  <img
    src="assets/smccoinslogo.png"
    alt="smc coins logo"
    id="logo"
    [routerLink]="authenticateRoute()"
  />
  <div class="flex-auto"></div>
  @if (windowWidth > 1090) {
  <nav class="flex gap-2">
    <p-button
      [text]="!['/home', '/user/dashboard'].includes(getCurrentPageRoute())"
      [severity]="
        ['/home', '/user/dashboard'].includes(getCurrentPageRoute())
          ? 'primary'
          : 'contrast'
      "
      [routerLink]="authenticateRoute()"
      label="Home"
      styleClass="uppercase"
    >
    </p-button>
    <p-button
      [text]="!getCurrentPageRoute().startsWith('/product')"
      [severity]="
        getCurrentPageRoute().startsWith('/product') ? 'primary' : 'contrast'
      "
      routerLink="/product"
      label="Products"
      styleClass="uppercase"
    >
    </p-button>
    <p-button
      [text]="getCurrentPageRoute() != '/buy-gold'"
      [severity]="getCurrentPageRoute() != '/buy-gold' ? 'contrast' : 'primary'"
      routerLink="/buy-gold"
      label="Buy Gold"
      styleClass="uppercase"
    >
    </p-button>
    <!-- <button pButton [text]="true" severity="contrast" routerLink="/sell-gold">
      Sell Gold
    </button> -->
    <p-button
      [text]="getCurrentPageRoute() != '/sip'"
      [severity]="getCurrentPageRoute() != '/sip' ? 'contrast' : 'primary'"
      routerLink="/sip"
      (click)="updateStage()"
      label="SIP"
    >
    </p-button>
    <!-- <button pButton [text]="true" severity="contrast">Information</button> -->
    @if(isVisible()){
    <p-button
      [text]="getCurrentPageRoute() != '/contact-us'"
      [severity]="
        getCurrentPageRoute() != '/contact-us' ? 'contrast' : 'primary'
      "
      routerLink="/contact-us"
      label="Contact Us"
      styleClass="uppercase"
    >
    </p-button>
    }
  </nav>
  <div class="flex-auto"></div>
  } @if (windowWidth < 1090) {
  <p-button
    icon="pi pi-bars"
    text="true"
    class="ml-2"
    (onClick)="showSidebar = true"
  ></p-button>
  } @if(isVisible()) {
  <p-button
    icon="pi pi-user"
    [label]="username()"
    text
    class="profile"
    (onClick)="profileOverlayPanel.toggle($event)"
  >
  </p-button>
  <p-overlayPanel #profileOverlayPanel>
    <div class="flex flex-column gap-2">
      @if (isAdmin()) {
      <p-button
        label="Admin Panel"
        routerLink="/admin/transactions"
        class="w-full"
        styleClass="w-full"
      ></p-button>
      }
      <p-button
        text
        severity="contrast"
        label="View Transaction"
        routerLink="transactions"
        class="w-full"
        styleClass="w-full"
      ></p-button>
      <p-button
        text
        severity="contrast"
        label="My Profile"
        (onClick)="navigationToProfile()"
        class="w-full"
        styleClass="w-full"
      ></p-button>
      <p-button
        outlined
        severity="contrast"
        label="Sign Out"
        severity="danger"
        (onClick)="signOutUser()"
        class="w-full"
        styleClass="w-full"
      ></p-button>
    </div>
  </p-overlayPanel>
  } @else {
  <p-button label="Login / Sign Up" (onClick)="showDialog()"></p-button>
  } @if (isVisible()) {
  <p-buttonGroup class="ml-2">
    @if (cartAmount > 0) {
    <p-button
      (onClick)="cartOverlayPanel.toggle($event)"
      icon="pi pi-shopping-cart"
      label="Cart"
      severity="secondary"
      [badge]="cartAmount > 0 ? cartAmount.toString() : undefined"
    ></p-button>
    }
    <p-overlayPanel #cartOverlayPanel>
      <div id="cart-overlay" style="width: 256px">
        <div
          class="mb-1 pb-1 border-bottom-1 surface-border text-lg font-bold flex justify-content-between align-items-center"
        >
          <div>Items in cart</div>
          <button
            pButton
            (click)="clearCart()"
            severity="danger"
            size="small"
            text
          >
            Clear Cart
          </button>
        </div>
        <!-- <div class="actions w-full"> -->
        <!-- View Cart -->
        <app-cart-view></app-cart-view>
        <!-- </div> -->
      </div>
    </p-overlayPanel>
    @if (cartAmount > 0) {
    <p-button label="Checkout" routerLink="/checkout"></p-button>
    }
  </p-buttonGroup>
  }
</header>
<p-sidebar
  [(visible)]="showSidebar"
  position="right"
  [fullScreen]="windowWidth < 400"
>
  <nav class="flex flex-column gap-2">
    <button
      pButton
      [text]="true"
      severity="contrast"
      [routerLink]="authenticateRoute()"
      size="large"
      (click)="showSidebar = false"
    >
      Home
    </button>
    <button
      pButton
      [text]="true"
      severity="contrast"
      routerLink="/product"
      size="large"
      (click)="showSidebar = false"
    >
      Products
    </button>
    <button
      pButton
      [text]="true"
      severity="contrast"
      routerLink="/buy-gold"
      size="large"
      (click)="showSidebar = false"
    >
      Buy Gold
    </button>
    <!-- <button
      pButton
      [text]="true"
      severity="contrast"
      routerLink="/sell-gold"
      size="large"
      (click)="showSidebar = false"
    >
      Sell Gold
    </button> -->
    <button
      pButton
      [text]="true"
      severity="contrast"
      size="large"
      routerLink="/sip"
      (click)="updateStage()"
      (click)="showSidebar = false"
    >
      SIP
    </button>
    <!-- <button
      pButton
      [text]="true"
      severity="contrast"
      size="large"
      (click)="showSidebar = false"
    >
      Information
    </button> -->
    <button
      pButton
      [text]="true"
      severity="contrast"
      size="large"
      (click)="showSidebar = false"
    >
      Contact Us
    </button>
  </nav>
</p-sidebar>

<!-- Body -->
<article>
  <ng-content></ng-content>
  <router-outlet />
</article>

<!-- Primary Footer -->
<footer id="primary-footer">
  <img
    src="assets/smccoinslogo.png"
    alt="smc coins logo"
    id="logo"
    style="width: 200px; align-self: flex-start"
    [routerLink]="authenticateRoute()"
  />

  <!-- Spacer -->
  <div class="flex-auto"></div>

  <section class="contact-us">
    <h3>Contact Us</h3>
    <div>
      <div class="row">
        <div class="pi pi-map-marker"></div>
        <div class="content">{{ location() }}</div>
      </div>
      <div class="row">
        <div class="pi pi-phone"></div>
        <div class="content">
          <a [href]="contactNoLink()">{{ contactNo() }}</a>
        </div>
      </div>
      <div class="row">
        <div class="pi pi-envelope"></div>
        <div class="content">
          <a [href]="contactMailLink()">{{ contactMail() }}</a>
        </div>
      </div>
    </div>
  </section>

  <section class="information">
    <h3>Information</h3>
    <ul>
      <li><a href="info/about-us">About Us</a></li>
      <li><a href="info/privacy-policy">Privacy Policy</a></li>
      <li><a href="info/terms-and-conditions">Terms and Condition</a></li>
      <!-- <li><a href="info/disclaimer">Disclaimer</a></li> -->
      <li><a href="info/shipping-policy">Shipping Policy</a></li>
      <li><a href="info/cancellation-return-policy">Cancellation & Return Policy</a></li>
    </ul>
  </section>

  <section class="customer-service">
    <h3>Customer Service</h3>
    <ul>
      <li><a href="/contact-us">Contact Us</a></li>
      <!-- <li><a href="forms/complaints">Complaints</a></li> -->
      <li><a href="info/faq">FAQ</a></li>
      <!-- <li><a href="info/feedback">Feedback</a></li> -->
    </ul>
  </section>

  <!-- <section class="account">
    <h3>Account</h3>
    <ul>
      <li><a href="users/wallet">My Wallet</a></li>
      <li><a href="users/orders">Order History</a></li>
      <li><a href="users/wishlist">Wishlist</a></li>
      <li><a href="fomrs/newsletter">Newsletter</a></li>
    </ul>
  </section> -->

  <!-- Spacer -->
  <div class="flex-auto"></div>
</footer>

<!-- <footer id="newsletter-footer">
  <fa-icon [icon]="icons.faEnvelopeOpen" size="xl" class="mr-3"></fa-icon>
  <h3>Newsletter</h3>
  <div class="newsletter-text">Subscribe to notification about our store</div>
  <form>
    <p-inputGroup>
      <input
        type="email"
        pInputText
        placeholder="Enter your email address ..."
      />
      <button type="submit" pButton icon="pi pi-angle-right"></button>
    </p-inputGroup>
  </form>
</footer> -->

<footer id="bottom-footer">
  <div class="copy">&copy; Pulin Comtrade Limited</div>
  <div class="flex-auto"></div>
  <div class="social-handles">
    <a target="_blank" href="https://www.facebook.com/SMCCoins?mibextid=LQQJ4d"
      ><img src="assets/social/facebook-logo.webp" alt="facebook"
    /></a>
    <a
      target="_blank"
      href="https://www.instagram.com/smccoins?igsh=MWJqbzcxZTloNTRzeQ=="
      ><img src="assets/social/instagram-logo.avif" alt="instagram"
    /></a>
    <!-- <a target="_blank" href="#"><img src="social/linkedin-logo.png" alt="linkedin"></a> -->
    <!-- <a target="_blank" href="https://x.com/smc_coins?s=11"><img src="social/twitter-logo.png" alt="twitter"></a> -->
    <a target="_blank" href="https://x.com/smc_coins?s=11"
      ><img src="assets/social/x-logo.avif" alt="x"
    /></a>
  </div>
</footer>

<!-- dialog -->
