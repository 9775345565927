import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputNumber, InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import {
  LoginPageState,
  LoginSignupService,
} from '../../../services/login-signup/login-signup.service';
import {
  finalize,
  interval,
  map,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { CommonModule } from '@angular/common';
import { InputOtpModule } from 'primeng/inputotp';
import { PasswordModule } from 'primeng/password';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { OtpService } from '../../../services/login-signup/otp.service';
import { UsersService } from '../../../services/users/users.service';
import { Router } from '@angular/router';
import { MustMatch } from '../../../validators/password-mismatch.validator';
import { AuthService } from '../../../services/auth.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { ConfigService } from '../../../services/config.service';
import { CommonService } from '../../../services/common/common.service';

@Component({
  selector: 'app-login-signup',
  standalone: true,
  imports: [
    ButtonModule,
    DialogModule,
    InputNumberModule,
    InputTextModule,
    ReactiveFormsModule,
    CommonModule,
    RippleModule,
    ToastModule,
    PasswordModule,
    InputOtpModule,
    InputMaskModule,
  ],
  providers: [DialogService],
  templateUrl: './login-signup.component.html',
  styleUrl: './login-signup.component.scss',
})
export class LoginSignupComponent {
  returnUrl: string = '/user/dashboard';
  dialogRef: DynamicDialogRef | undefined;
  currentState: LoginPageState = LoginPageState.PhoneNumber;
  stateSubscription?: Subscription;
  LoginPageState = LoginPageState;
  mobileNumber: string = '0';
  priceLockTime: number = 0 
  timeLeft: number = 5; // Set your initial time here (in seconds)
  timerInterval?: Subscription;
  loading: boolean = false;
  userId: number = 0;

  otpForm: FormGroup = new FormGroup({
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(/[6-9]\d{2}-\d{4}-\d{3}/),
    ]),
    otpInput: new FormControl('', [
      Validators.required,
      Validators.pattern(/\d{6}/),
    ]),
    createPin: new FormControl('', [Validators.required]),
    confirmPin: new FormControl('', [Validators.required]),
    loginPin: new FormControl('', [Validators.required]),
  });

  pinMatch = false;

  constructor(
    public _ref: DynamicDialogRef,
    public dialogService: DialogService,
    public _config: DynamicDialogConfig,
    public config: DynamicDialogConfig,
    private loginStateService: LoginSignupService,
    private otpService: OtpService,
    private userService: UsersService,
    private configService: ConfigService,
    private messageService: MessageService,
    private authService: AuthService,
    private readonly router: Router,
    private commonService:CommonService
  ) {
    if (this.config.data?.returnUrl) {
      console.log('login comp', this.returnUrl, this.config);

      console.log('login comp', this.returnUrl, this.config);

      this.returnUrl = this.config.data.returnUrl;
      console.log(' after login comp', this.returnUrl, this.config);
    }

    this.configService.getConfigValues('resendOtpTimer').subscribe({
      next: (response) => {
        if (response.statusCode === 200) {

          this.priceLockTime = response.data;
          this.timeLeft = this.priceLockTime;
        }
      },
    });
  }

  ngOnInit() {
    this.stateSubscription = this.loginStateService
      .getCurrentState()
      .subscribe((state) => (this.currentState = state));
  }

  ngOnDestroy() {
    this.loginStateService.setCurrentState(LoginPageState.PhoneNumber);
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    this.stopTimer();
  }

  changeStateDialog(
    dialogWidth: string,
    dialogHeader: string,
    state: LoginPageState
  ) {
    this.loading = false;
  }

  proceedToOtp() {
    this.loading = true;
    this.mobileNumber = this.otpForm
      .get('phoneNumber')
      ?.value.split('-')
      .join('');

    this.userService.findUserByPhoneNumber(this.mobileNumber).subscribe({
      next: (response: any) => {
        if (response.statusCode === 200) {
          if (!response.data.isPinPresent) {
            //-------------------------------------
            // this.userId = response.data.userId;
            // this.loading = false; // Stop loading as no need to send OTP
            // this._config.width = '20em';
            // this._config.header = 'Create PIN';
            // this.loginStateService.setCurrentState(LoginPageState.SetPin);
            this.sendOtp(false, true);
          } else if (response.data.isPinPresent) {
            this.userId = response.data.userId;
            this.loading = false; // Stop loading as no need to send OTP
            this._config.width = '25em';
            this._config.header = 'Login with PIN';
            this.loginStateService.setCurrentState(LoginPageState.EnterPin);
          }
        } else {
          this.sendOtp(); // Proceed to OTP if user not found
        }
      },
      error: (error) => {
        console.error('Error finding user', error);
        this.sendOtp(); // Proceed to OTP if an error occurs
      },
    });
  }

  resendMessage?: string | null;
  sendOtp(resend: boolean = false, isLogin: boolean = false) {
    var ifResend = resend;
    var ifIsLogin = isLogin;
    if (ifResend) {
      this.resendMessage = 'Otp Sent to your Mobile Number';
      this.timeLeft = 5;
      this.startTimer();
    }
    this.otpService.sendOtp(this.mobileNumber).subscribe({
      next: (response) => {
        if (ifIsLogin) {
          this.loading = false;
          this.loginStateService.setCurrentState(LoginPageState.EnterLoginOtp);
          this.startTimer();

          this._config.header = 'Verify OTP';
          this._config.width = '25rem';
        } else {
          this.loading = false;
          this.loginStateService.setCurrentState(LoginPageState.EnterOtp);
          this.startTimer();
          this._config.header = 'Verify OTP';
          this._config.width = '25rem';
        }
      },
      error: (error) => {
        this.commonService.showMessage('error','Exceed limit for multiple login today')
        console.error('Error sending OTP', error);
        this.error = error.error.message;

        // Handle error (e.g., show error message to user)
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
  verifyOtp() {
    this.loading = true;
    const otp = this.otpForm.get('otpInput')?.value;
    this.otpService.verifyOtp(this.mobileNumber, otp).subscribe({
      next: (response: any) => {
        if (response.statusCode === 201) {
          this.loading = false;
          console.log(response.data);
          this.userId = response.data.userId;
          this.mobileNumber = response.data.phoneNumber;
          this.loginStateService.setCurrentState(LoginPageState.VerifyOtp);
          this._config.closable = false;
          this._config.header = 'OTP Verified';
          this._config.width = '25rem';
        } else if (response.statusCode === 400) {
          this.showError(response.message);
        }
      },
      error: (error) => {
        console.error('Error Validating OTP', error);
        // Handle error (e.g., show error message to user)
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  verifyLoginOtp() {
    this.loading = true;
    const otp = this.otpForm.get('otpInput')?.value;
    this.otpService.verifyLoginOtp(this.mobileNumber, otp).subscribe({
      next: (response: any) => {
        if (response.statusCode === 200) {
          this.loading = false;
          console.log('resssss', response.statusCode);
          this.loginStateService.setCurrentState(LoginPageState.VerifyLoginOtp);
          this._config.closable = false;
          this._config.header = 'OTP Verified';
          this._config.width = '25rem';
        } else if (response.statusCode === 400) {
          this.showError(response.message);
        }
      },
      error: (error) => {
        console.error('Error Validating OTP', error);
        // Handle error (e.g., show error message to user)
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  error: string | null = null;
  showError(error: string) {
    this.error = error;
  }

  startTimer() {
    this.timerInterval = interval(1000).subscribe(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.stopTimer();
      }
    });
  }

  stopTimer() {
    if (this.timerInterval) {
      this.timerInterval.unsubscribe();
    }
  }

  get formattedTime() {
    const minutes: number = Math.floor(this.timeLeft / 60);
    const seconds: number = this.timeLeft % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  }

  isPhoneNumberValid(): boolean {
    return this.otpForm.get('phoneNumber')?.valid ?? false;
  }

  isOtpValid(): boolean {
    return this.otpForm.get('otpInput')?.valid ?? false;
  }

  mustMatch(): boolean {
    const createPin = this.otpForm.get('createPin')?.value;
    const confirmPin = this.otpForm.get('confirmPin')?.value;
    if (
      this.otpForm.get('createPin')?.valid &&
      this.otpForm.get('confirmPin')?.value &&
      createPin === confirmPin
    ) {
      return true;
    }
    return false;
  }

  changePhoneNumber() {
    this._config.header = 'Enter Phone Number';
    this.loginStateService.setCurrentState(LoginPageState.PhoneNumber);
  }
  createPin() {
    this.loading = true;
    const pin = this.otpForm.get('createPin')?.value;
    const reqBody = {
      pin: pin,
    };
    this.userService.updateUserById(this.userId, reqBody).subscribe({
      next: (response: any) => {
        if (response.statusCode === 200) {
          this.loginStateService.setCurrentState(LoginPageState.Verified);
          // this._config.header = 'Verified';
          // this._config.width = '25rem';

          const loginReqBody = {
            phone: this.mobileNumber,
            pin: pin,
          };
          this.authService.logIn(loginReqBody).subscribe({
            next: (res: any) => {
              if (res.statusCode === 200) {
                this.loading = false;
                this.loginStateService.setCurrentState(
                  LoginPageState.PinUpdated
                );
                this._config.header = '';
                this._config.width = '25rem';

                setTimeout(() => {
                  this._ref.close();
                  this.router.navigate(['/user/dashboard'], {
                    queryParams: { basicDetails: true },
                  });
                }, 2000);
              }
            },
          });
        }
      },
    });
  }
  login() {
    this.loading = true;
    const pin = this.otpForm.get('loginPin')?.value;

    const reqBody = {
      phone: this.mobileNumber,
      pin: pin,
    };
    this.authService.logIn(reqBody).subscribe({
      next: (res: any) => {
        if (res.statusCode === 200) {
          this.loading = false;
          this.loginStateService.setCurrentState(LoginPageState.Verified);
          this._config.header = 'Verified';
          this._config.width = '25rem';

          setTimeout(() => {
            // this.router.navigate([this.returnUrl]);
            this.router.navigateByUrl(this.returnUrl);
            this._ref.close();
          }, 2000);
        }
      },
    });
  }

  otpverificationDone() {
    this.loading = false; // Stop loading as no need to send OTP

    //---Set Pin---
    // this._config.width = '20em';
    // this._config.header = 'Create PIN';
    // this.loginStateService.setCurrentState(LoginPageState.SetPin);

    //--otp verified--//

    const reqBody = {
      phone: this.mobileNumber,
    };
    this.authService.logIn(reqBody).subscribe({
      next: (res: any) => {
        if (res.statusCode === 200) {
          setTimeout(() => {
            this._ref.close();
            this.router
              .navigateByUrl(this.returnUrl)
              .then((x) => console.log(x))
              .catch((e) => console.log(e));
          }, 100);
        }
      },
    });
  }

  otpverificationLoginDone() {
    this.loading = false; // Stop loading as no need to send OTP

    //---Set Pin---
    // this._config.width = '20em';
    // this._config.header = 'Create PIN';
    // this.loginStateService.setCurrentState(LoginPageState.SetPin);

    //--otp verified--//

    const reqBody = {
      phone: this.mobileNumber,
    };
    this.authService.logIn(reqBody).subscribe({
      next: (res: any) => {
        if (res.statusCode === 200) {
          setTimeout(() => {
            this._ref.close();
            this.router
              .navigateByUrl(this.returnUrl)
          }, 1000);
          // this.router.navigateByUrl(this.returnUrl);
          // this._ref.close();
        }
      },
    });
  }
}
