<ng-container [ngSwitch]="currentState">
  <div *ngSwitchCase="LoginPageState.PhoneNumber">
    <!-- <ng-container pTemplate='header'>Login/SignUp</ng-container> -->
    <span class="p-text-secondary block mb-2">Enter Mobile Number</span>
    <div class="flex flex-column align-items-left gap-3 mb-3">
      <form [formGroup]="otpForm">
        <p-inputMask
          mask="999-9999-999"
          formControlName="phoneNumber"
          class="w-full"
          styleClass="w-full"
        />
        @if(error) {
        <div class="text-red-500 text-sm mt-1">{{ error }}</div>
        }
      </form>
    </div>
    <div class="flex justify-content-start gap-2 mb-3">
      <p-button
        label="Proceed"
        class="w-full"
        styleClass="w-full"
        [disabled]="!isPhoneNumberValid()"
        (onClick)="proceedToOtp()"
        loadingIcon="pi pi-spin pi-spinner"
        [loading]="loading"
        iconPos="right"
      />
    </div>
    <span class="p-text-secondary block mb-2"
      >By Proceeding you agree to our
      <a
        href="/info/terms-and-conditions"
        target="_blank"
        class="text-orange-500 cursor-pointer"
        >Terms and Conditions</a
      ></span
    >
  </div>
  <div *ngSwitchCase="LoginPageState.EnterOtp">
    <span class="p-text-secondary block mb-3"
      >Verify Mobile Number <strong>{{ mobileNumber }}</strong></span
    >
    @if (resendMessage) {<span class="text-orange-500 block mb-3">{{
      resendMessage
    }}</span
    >}
    <span class="p-text-secondary block mb-3"
      >Enter One Time Password (OTP)</span
    >
    <form action="" [formGroup]="otpForm">
      <div class="card flex justify-content-left mt-2 mb-3 flex-column">
        <p-inputOtp
          [integerOnly]="true"
          [length]="6"
          formControlName="otpInput"
        />
      </div>
    </form>
    <span class="p-text-secondary block mb-3">
      <span>Didn't receive OTP?</span>
      @if (timeLeft>0) {
      <span>
        <i class="pi pi-clock mr-2 ml-1"></i>
        Resend in
        {{ formattedTime }}
      </span>
      }@else{
      <span
        class="text-orange-500 font-semibold underline cursor-pointer"
        (click)="sendOtp(true)"
      >
        Resend Otp
      </span>
      }</span
    >
    @if(error) {
    <div class="text-red-500 text-sm mb-3 mt-1">{{ error }}</div>
    }
    <div class="flex justify-content-start gap-2 mb-3">
      <p-button
        label="Verify"
        class="w-full"
        styleClass="w-full"
        (onClick)="verifyOtp()"
        [disabled]="!isOtpValid()"
        loadingIcon="pi pi-spin pi-spinner"
        [loading]="loading"
        iconPos="right"
      />
    </div>
  </div>

  <div *ngSwitchCase="LoginPageState.EnterLoginOtp">
    <span class="p-text-secondary block mb-3"
      >Verify Mobile Numberrrr <strong>{{ mobileNumber }}</strong></span
    >
    @if (resendMessage) {<span class="text-orange-500 block mb-3">{{
      resendMessage
    }}</span
    >}
    <span class="p-text-secondary block mb-3"
      >Enter One Time Password (OTP)</span
    >
    <form action="" [formGroup]="otpForm">
      <div class="card flex justify-content-left mt-2 mb-3 flex-column">
        <p-inputOtp
          [integerOnly]="true"
          [length]="6"
          formControlName="otpInput"
        />
      </div>
    </form>
    <span class="p-text-secondary block mb-3"
      >Didn't receive OTP? @if (timeLeft>0) {<span
        ><i class="pi pi-clock mr-2 ml-1"></i>Resend in
        {{ formattedTime }}</span
      >}@else{<span
        class="text-orange-500 font-semibold underline cursor-pointer"
        (click)="sendOtp(true)"
        >Resend Otp</span
      >}</span
    >
    @if(error) {
    <div class="text-red-500 text-sm mb-3 mt-1">{{ error }}</div>
    }
    <div class="flex justify-content-start gap-2 mb-3">
      <p-button
        label="Verify"
        severity="success"
        class="w-full"
        styleClass="w-full"
        (onClick)="verifyLoginOtp()"
        [disabled]="!isOtpValid()"
        loadingIcon="pi pi-spin pi-spinner"
        [loading]="loading"
        iconPos="right"
      />
    </div>
  </div>

  <div *ngSwitchCase="LoginPageState.VerifyOtp">
    <div class="flex flex-column align-items-center">
      <i class="pi pi-verified text-orange-500 text-6xl mb-3"></i>
      <span
        class="p-text-secondary text-green-400 text-center text-2xl font-semibold block mb-3"
      >
        OTP Verified
      </span>
    </div>
    <div class="flex justify-content-start gap-2 mb-3">
      <p-button
        label="Done"
        severity="success"
        class="w-full"
        styleClass="w-full"
        loadingIcon="pi pi-spin pi-spinner"
        [loading]="loading"
        iconPos="right"
        (onClick)="otpverificationDone()"
      />
    </div>
  </div>

  <div *ngSwitchCase="LoginPageState.VerifyLoginOtp">
    <div class="flex flex-column align-items-center">
      <i class="pi pi-verified text-orange-500 text-6xl mb-3"></i>
      <span
        class="p-text-secondary text-green-400 text-center text-2xl font-semibold block mb-3"
        >OTP Verified</span
      >
    </div>
    <div class="flex justify-content-start gap-2 mb-3">
      <p-button
        label="Done"
        severity="success"
        class="w-full"
        styleClass="w-full"
        loadingIcon="pi pi-spin pi-spinner"
        [loading]="loading"
        iconPos="right"
        (onClick)="otpverificationLoginDone()"
      />
    </div>
  </div>

  <div *ngSwitchCase="LoginPageState.SetPin">
    <span class="p-text-secondary block mb-4"
      >Enter 6 digit PIN to easy login</span
    >
    <form [formGroup]="otpForm" class="flex flex-column">
      <p-password
        (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
        pInputNumber
        formControlName="createPin"
        [toggleMask]="true"
        class="mb-3"
        maxLength="6"
        placeholder="Enter 6 digit PIN (Password)"
        [feedback]="false"
      />
      <p-password
        (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
        formControlName="confirmPin"
        [toggleMask]="true"
        class="mb-2"
        maxLength="6"
        placeholder="Confirm PIN (Password)"
        [feedback]="false"
      />
      @if(otpForm.get('createPin')?.touched && !mustMatch()) {
      <span class="text-red-500 text-xs mb-4">*PIN do not match</span>
      }
    </form>

    <div class="flex justify-content-start gap-2 mb-3">
      <p-button
        label="Create"
        severity="success"
        class="w-full"
        styleClass="w-full"
        [disabled]="!mustMatch()"
        (onClick)="createPin()"
        loadingIcon="pi pi-spin pi-spinner"
        [loading]="loading"
        iconPos="right"
      />
    </div>
  </div>

  <div *ngSwitchCase="LoginPageState.EnterPin">
    <div class="p-text-secondary block mb-3">
      <span>Verify Mobile Number </span>
      <strong>{{ mobileNumber }} </strong>
      <span
        class="text-orange-500 underline cursor-pointer ml-2"
        (click)="changePhoneNumber()"
      >
        Change
      </span>
    </div>
    <form [formGroup]="otpForm" class="flex flex-column">
      <p-password
        autofocus
        autocomplete="password"
        (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
        pInputNumber
        formControlName="loginPin"
        [toggleMask]="true"
        class="mb-1 password w-full"
        styleClass="w-full"
        maxLength="6"
        placeholder="Enter 6 digit PIN (Password)"
        [feedback]="false"
      />
    </form>
    <span class="text-orange-500 text-sm underline cursor-pointer block mb-3">
      Forgot PIN
    </span>

    <div class="flex justify-content-start gap-2 mb-3">
      <p-button
        label="Login"
        class="w-full"
        styleClass="w-full"
        (onClick)="login()"
        loadingIcon="pi pi-spin pi-spinner"
        [loading]="loading"
        iconPos="right"
      />
    </div>
  </div>

  <div *ngSwitchCase="LoginPageState.Verified">
    <div class="flex flex-column align-items-center">
      <i class="pi pi-check-circle text-orange-500 text-6xl mb-3"></i>
      <span
        class="p-text-secondary text-green-400 text-center text-2xl font-semibold block mb-3"
        >Verified</span
      >
    </div>
  </div>

  <div *ngSwitchCase="LoginPageState.PinUpdated">
    <div class="flex flex-column align-items-center">
      <i class="pi pi-check-circle text-orange-500 text-6xl mb-3"></i>
      <span
        class="p-text-secondary text-green-400 text-center text-2xl font-semibold block mb-3"
        >Pin Updated Successfully</span
      >
    </div>
  </div>
</ng-container>
