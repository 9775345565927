<section class="container">
  <div class="card flex flex-column align-items-center">
    <p-card [style]="{ width: '100%', maxWidth: '1200px' }">
      <div class="confirm">
        <p style="font-size: 24px" class="font-bold mt-0 mb-0">Products</p>
        <p *ngIf="errorMessage" class="error-label block">
          {{ errorMessage }}
        </p>
        <div class="flex justify-content-start align-items-center">
          <p-button
            label="Add Product"
            icon="pi pi-plus"
            (onClick)="showAddProductDialog()"
          />
          <p-button
            label="Refresh"
            class="ml-2"
            (click)="refreshTable()"
            icon="pi pi-refresh"
          ></p-button>
          <p-button
            label="Clear"
            class="ml-2"
            (click)="clearFilters()"
            icon="pi pi-filter-slash"
          ></p-button>
        </div>
      </div>
      <p-card [style]="{ 'margin-top': '1.5rem' }">
        <p-table
          #dt1
          [value]="products"
          dataKey="id"
          [rows]="10"
          [rowsPerPageOptions]="[10, 25, 50]"
          [paginator]="true"
          [globalFilterFields]="[
            'name',
            'materialType',
            'productType',
            'productSeries',
            'weight',
            'purity',
            'price',
            'makingCharge',
            'discountPercent',
            'discountAmount',
            'inventory',
            'productStatus'
          ]"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="min-width: 12rem">
                <div class="flex align-items-center">Action</div>
              </th>
              <th style="min-width: 12rem">
                <div class="flex align-items-center">
                  Name
                  <p-columnFilter type="text" field="name" display="menu" />
                </div>
              </th>
              <th style="min-width: 12rem">
                <div class="flex align-items-center">
                  Material Type
                  <p-columnFilter
                    type="text"
                    field="materialType"
                    display="menu"
                  />
                </div>
              </th>
              <th style="min-width: 12rem">
                <div class="flex align-items-center">
                  Product Code
                  <p-columnFilter
                    type="text"
                    field="materialType"
                    display="menu"
                  />
                </div>
              </th>
              <th style="min-width: 12rem">
                <div class="flex align-items-center">
                  Product Series
                  <p-columnFilter
                    type="text"
                    field="productSeries"
                    display="menu"
                  />
                </div>
              </th>
              <th style="min-width: 12rem">
                <div class="flex align-items-center">
                  Product Shape
                  <p-columnFilter
                    type="text"
                    field="productType"
                    display="menu"
                  />
                </div>
              </th>
              <th style="min-width: 8rem">
                <div class="flex align-items-center">
                  Weight
                  <p-columnFilter type="text" field="weight" display="menu" />
                </div>
              </th>
              <th style="min-width: 8rem">
                <div class="flex align-items-center">
                  Purity
                  <p-columnFilter type="text" field="purity" display="menu" />
                </div>
              </th>
              <th style="min-width: 8rem; text-align: center">
                <div class="flex align-items-center">
                  Price
                  <p-columnFilter
                    type="text"
                    field="price"
                    display="menu"
                    currency="INR"
                  />
                </div>
              </th>
              <th style="min-width: 12rem; text-align: center">
                <div class="flex align-items-center">
                  Making Charge
                  <p-columnFilter
                    type="text"
                    field="makingCharge"
                    display="menu"
                  />
                </div>
              </th>
              <th style="min-width: 13rem; text-align: center">
                <div class="flex align-items-center">
                  Discount Percent
                  <p-columnFilter
                    type="text"
                    field="discountPercent"
                    display="menu"
                  />
                </div>
              </th>
              <th style="min-width: 13rem; text-align: center">
                <div class="flex align-items-center">
                  Discount Amount
                  <p-columnFilter
                    type="text"
                    field="discountAmount"
                    display="menu"
                  />
                </div>
              </th>
              <th style="min-width: 6rem; text-align: center">
                <div class="flex align-items-center">
                  Inventory
                  <p-columnFilter
                    type="text"
                    field="inventory"
                    display="menu"
                  />
                </div>
              </th>
              <th style="min-width: 12rem; text-align: center">
                Product Status
                <p-columnFilter
                  type="text"
                  field="productStatus"
                  display="menu"
                />
              </th>
              <th style="min-width: 8rem; text-align: center">Edit Status</th>
              <th style="min-width: 8rem; text-align: center">Edit Details</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td>
                <div class="flex align-items-center">
                  <p-fileUpload
                    mode="basic"
                    class="mr-4"
                    [files]="attachments"
                    multiple="multiple"
                    [customUpload]="true"
                    [auto]="true"
                    chooseIcon="pi pi-upload"
                    styleClass="ui-fileupload-override"
                    (uploadHandler)="handleFileInput($event, product)"
                  ></p-fileUpload>

                  <i
                    class="pi pi-eye text-blue-600 mr-4 cursor-pointer"
                    style="font-size: 14px"
                    (click)="showImg(product)"
                  ></i>
                  <a
                    class="fileInfo"
                    (click)="
                      showFileDetails(product); filesOverlay.toggle($event)
                    "
                  >
                    {{ product.images.length }}
                    Files
                  </a>
                </div>
              </td>
              <td>
                {{ product.name }}
              </td>
              <td>
                {{ product.materialType }}
              </td>
              <td>
                {{ product.productCode }}
              </td>
              <td>
                {{ product.productSeries }}
              </td>
              <td>
                {{ product.productType }}
              </td>
              <td>
                {{ product.weight }}
              </td>
              <td>
                {{ product.purity }}
              </td>
              <td>
                {{ product.price | currency : "INR" }}
              </td>
              <td>
                {{ product.makingCharge | currency : "INR" }}
              </td>
              <td>
                {{ product.discountPercent }}
              </td>
              <td>
                {{ product.discountAmount | currency : "INR" }}
              </td>
              <td>
                {{ product.inventory }}
              </td>
              <td style="text-align: center">
                <p-tag
                  [value]="getProductStatusLabel(product.isActive)"
                  [severity]="getProductStatusSeverity(product.isActive)"
                />
              </td>
              <td style="text-align: center">
                <p-button
                  class="mr-1"
                  icon="pi pi-pencil"
                  rounded
                  text
                  severity="success"
                  (onClick)="updateProductById(product.id)"
                ></p-button>
                @if(product.isActive) {
                <p-button
                  icon="pi pi-times"
                  rounded
                  text
                  severity="danger"
                  (onClick)="setProductStatus(product.id)"
                ></p-button>
                } @else {
                <p-button
                  icon="pi pi-check"
                  rounded
                  text
                  severity="success"
                  (onClick)="setProductStatus(product.id)"
                ></p-button>
                }
              </td>
              <td style="text-align: center">
                <p-button
                  icon="pi pi-pencil"
                  class="mx-1 p-button-rounded"
                  severity="success"
                  (onClick)="getCurrProductDetails(product.id)"
                ></p-button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">No products found.</td>
            </tr>
          </ng-template>
        </p-table>
      </p-card>
      <p-dialog
        [header]="dialogHeader"
        [(visible)]="displayAddProductDialog"
        [modal]="true"
        [closable]="false"
        [style]="{ width: '35vw' }"
      >
        <div class="p-fluid">
          <form [formGroup]="addProductForm" (ngSubmit)="addProduct()">
            <div class="p-field my-2">
              <label for="product-name"
                >Product name<span class="required error">*</span></label
              >
              <input
                id="product-name"
                formControlName="productName"
                type="text"
                pInputText
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('productName')?.invalid &&
                  addProductForm.get('productName')?.touched
                "
              >
                Product name is required.
              </div>
            </div>
            <div class="p-field my-2">
              <label for="material-type"
                >Material type<span class="required error">*</span></label
              >
              <p-dropdown
                formControlName="materialType"
                [options]="materialTypeOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Select a Material Type"
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('materialType')?.invalid &&
                  addProductForm.get('materialType')?.touched
                "
              >
                Material type is required.
              </div>
            </div>
            <div class="p-field my-2">
              <label for="product-type"
                >Product shape<span class="required error">*</span></label
              >
              <p-dropdown
                formControlName="productType"
                [options]="productTypeOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Select a Product Shape"
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('productType')?.invalid &&
                  addProductForm.get('productType')?.touched
                "
              >
                Product shape is required.
              </div>
            </div>
            <div class="p-field my-2">
              <label for="product-code"
                >Product code<span class="required error">*</span></label
              >
              <input
                id="product-code"
                formControlName="productCode"
                type="text"
                pInputText
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('productCode')?.invalid &&
                  addProductForm.get('productCode')?.touched
                "
              >
                Product code is required.
              </div>
            </div>
            <div class="p-field my-2">
              <label for="material-type"
                >Product series<span class="required error">*</span></label
              >
              <p-dropdown
                formControlName="productSeries"
                [options]="productSeriesOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Select a Product Series"
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('productSeries')?.invalid &&
                  addProductForm.get('productSeries')?.touched
                "
              >
                Product series is required.
              </div>
            </div>
            <div class="p-field my-2">
              <label for="weight"
                >Weight<span class="required error">*</span></label
              >
              <input
                id="weight"
                formControlName="weight"
                type="text"
                pInputText
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('weight')?.invalid &&
                  (addProductForm.get('weight')?.touched ||
                    addProductForm.get('weight')?.dirty)
                "
              >
                Weight must be a valid number and is required.
              </div>
            </div>
            <div class="p-field my-2">
              <label for="purity"
                >Purity<span class="required error">*</span></label
              >
              <input
                id="purity"
                formControlName="purity"
                type="text"
                pInputText
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('purity')?.invalid &&
                  (addProductForm.get('purity')?.touched ||
                    addProductForm.get('purity')?.dirty)
                "
              >
                Purity must be a valid number (e.g., 0.9999) and is required.
              </div>
            </div>
            <div class="p-field my-2">
              <label for="price"
                >Price<span class="required error">*</span></label
              >
              <input
                id="price"
                formControlName="price"
                type="text"
                pInputText
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('price')?.invalid &&
                  (addProductForm.get('price')?.touched ||
                    addProductForm.get('price')?.dirty)
                "
              >
                Price must be a valid number and is required.
              </div>
            </div>
            <div class="p-field my-2">
              <label for="making-charge">Making charge</label>
              <input
                id="making-charge"
                formControlName="makingCharge"
                type="text"
                pInputText
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('makingCharge')?.invalid &&
                  addProductForm.get('makingCharge')?.dirty
                "
              >
                Making charge must be a valid number.
              </div>
            </div>
            <div class="p-field my-2">
              <label for="discount-percent">Discount percent</label>
              <input
                id="discount-percent"
                formControlName="discountPercent"
                type="text"
                pInputText
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('discountPercent')?.invalid &&
                  addProductForm.get('discountPercent')?.dirty
                "
              >
                Discount percent must be a valid number.
              </div>
            </div>
            <div class="p-field my-2">
              <label for="discount-amount">Discount amount</label>
              <input
                id="discount-amount"
                formControlName="discountAmount"
                type="text"
                pInputText
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('discountAmount')?.invalid &&
                  addProductForm.get('discountAmount')?.dirty
                "
              >
                Discount amount must be a valid number.
              </div>
            </div>
            <div class="p-field my-2">
              <label for="quantity"
                >Quantity<span class="required error">*</span></label
              >
              <input
                id="quantity"
                formControlName="quantity"
                type="text"
                pInputText
              />
              <div
                class="my-2 error"
                *ngIf="
                  addProductForm.get('quantity')?.invalid &&
                  (addProductForm.get('quantity')?.touched ||
                    addProductForm.get('quantity')?.dirty)
                "
              >
                Quantity must be a valid number and is required.
              </div>
            </div>
            <div class="p-field-radiobutton my-2">
              <label>Is Featured</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton my-2">
                  <p-radioButton
                    name="isFeatured"
                    formControlName="isFeatured"
                    [value]="true"
                    label="Yes"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    name="isFeatured"
                    formControlName="isFeatured"
                    [value]="false"
                    label="No"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div class="p-field-radiobutton my-2">
              <label>Is Digital</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton my-2">
                  <p-radioButton
                    name="isDigital"
                    formControlName="isDigital"
                    [value]="true"
                    label="Yes"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    name="isDigital"
                    formControlName="isDigital"
                    [value]="false"
                    label="No"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </form>
        </div>
        <p-footer>
          <div class="footer-content">
            <div>
              <div class="error" *ngIf="allFieldsRequiredError">
                *Please fill all the required fields.
              </div>
            </div>
            <div class="buttons">
              @if(isUpdate) {
              <p-button
                label="Update"
                icon="pi pi-check"
                (onClick)="updateCurrentProduct()"
              />
              } @else {
              <p-button
                label="Save"
                icon="pi pi-check"
                (onClick)="addProduct()"
              />
              }
              <p-button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-secondary"
                (onClick)="hideAddProductDialog()"
              />
            </div>
          </div>
        </p-footer>
      </p-dialog>
      <p-dialog
        [header]="currentProduct"
        [modal]="true"
        [(visible)]="displayMultipleProductImageDialog"
        [style]="{ width: '50rem' }"
      >
        <p-card>
          <div
            *ngIf="!currentProductImages || currentProductImages.length === 0"
            class="p-text-center"
          >
            <h3>No images found for {{ currentProduct }}</h3>
          </div>
          <p-table
            *ngIf="currentProductImages && currentProductImages.length > 0"
            [value]="currentProductImages"
            [tableStyle]="{ 'min-width': '50rem' }"
            class="p-datatable-sm"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Images</th>
                <th>Actions</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-image>
              <tr>
                <td>
                  <p-image
                    [src]="image?.url"
                    [previewImageSrc]="image?.url"
                    alt="Image"
                    width="50"
                    [preview]="true"
                  />
                </td>
                <td>
                  <p-button
                    icon="pi pi-trash"
                    class="mx-1 p-button-rounded"
                    severity="danger"
                  ></p-button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-card>
        <p-card [style]="{ 'margin-top': '1.5rem' }">
          <p-fieldset legend="Upload New Image" class="mt-3">
            <div class="flex justify-content-start align-items-center mt-3">
              <label for="imageUpload" class="mr-5">Select an Image</label>
              <input
                type="file"
                id="imageUpload"
                (change)="onFilesSelected($event)"
                accept="image/*"
                style="display: none"
                #fileInput
              />
              <p-button
                label="Choose Image"
                icon="pi pi-image"
                class="p-button-outlined"
                [disabled]="selectedProductImageFiles.length > 0 || isUploading"
                (click)="fileInput.click()"
              ></p-button>
              <p-button
                label="Upload Image"
                icon="pi pi-upload"
                class="p-button-rounded ml-5"
                [disabled]="!selectedProductImageFiles.length || isUploading"
                (click)="uploadImages()"
                [disabled]="!selectedProductImageFiles"
              ></p-button>
            </div>
          </p-fieldset>
        </p-card>
      </p-dialog>

      <!-- Product detail dialog -->
      <p-dialog
        header="Details"
        [modal]="true"
        [(visible)]="isCurrProductDetailDialogVisible"
        [style]="{ width: '40rem' }"
      >
        <form [formGroup]="currProductDetailForm">
          <div formArrayName="details">
            <div
              *ngFor="let detail of details.controls; let i = index"
              [formGroupName]="i"
              class="detail-row"
            >
              <div class="flex justify-content-start align-items-center">
                <div class="p-field my-2">
                  <label for="key">Detail Type</label>
                  <input
                    formControlName="key"
                    placeholder="Enter detail type"
                    type="text"
                    pInputText
                  />
                </div>
                <div class="p-field my-2">
                  <label for="value">Detail Value</label>
                  <input
                    formControlName="value"
                    placeholder="Enter detail value"
                    type="text"
                    pInputText
                  />
                </div>
                <div class="p-field mt-4 mr-2">
                  <p-button
                    icon="pi pi-check"
                    severity="success"
                    (click)="triggerSaveDetail(i)"
                  ></p-button>
                </div>
                <div class="p-field mt-4">
                  <p-button
                    icon="pi pi-trash"
                    severity="danger"
                    (onClick)="deleteDetail(i)"
                  ></p-button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <p-footer>
          <p-button
            label="Add detail"
            icon="pi pi-plus"
            (click)="addDetail()"
          ></p-button>
        </p-footer>
      </p-dialog>
    </p-card>
  </div>
  <p-overlayPanel #filesOverlay [showCloseIcon]="true">
    <ng-template pTemplate>
      <p-table responsiveLayout="scroll" [value]="filesData">
        <ng-template pTemplate="header">
          <tr>
            <th>Images</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-columns="columns">
          <tr>
            <td>{{ data.url.split("smc-digi-gold/")[1] }}</td>
            <td>
              <i
                class="pi pi-trash text-pink-600 cursor-pointer"
                (click)="deleteFile(data)"
              ></i>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">No files found</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-overlayPanel>

  <p-dialog
    [(visible)]="showFiles"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: 'auto' }"
    [modal]="true"
    [draggable]="false"
    [styleClass]="'custom-dialog'"
    header="Uploaded Documents"
  >
    <div class="imageAlign flex flex-row mb-5">
      <div
        *ngFor="
          let item of images | paginate : { itemsPerPage: 5, currentPage: p }
        "
      >
        <div class="flex flex-column align-items-center mt-2">
          <p-image
          [src]="item.url"
          alt="{{ item.name }}"
          width="200"
          height="200"
          class="mr-3 ml-3 cursor-pointer"
        ></p-image>
        <p-button
                    icon="pi pi-trash"
                    class="mt-2 p-button-rounded"
                    severity="danger"
                    (click)="deleteFile(item)"
                  ></p-button>
        </div>
      </div>
    </div>

    <div class="col-md-12 flex justify-content-center">
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
  </p-dialog>
</section>
