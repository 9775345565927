import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CommonService } from './common/common.service';
import { EncryptionService } from './encryption.service';
import { environment } from '../../environments/environment';

// Use this to add vendor/other apis which don't require encryption/decryption
const VENDOR_API_URLS = [
  'https://vendorapi.com',
  environment.digigoldbevendor,
  'upload-files',
  'https://api.postalpincode.in',
];
const sessionStorageCache = ['/checkout','kyc/pan','pan-api'];

// Functional interceptor
export const ErrorInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);
  const messageService = inject(MessageService);
  const encryptionService = inject(EncryptionService);
  const commonService = inject(CommonService);
  commonService.show();

  const isVendorApi = VENDOR_API_URLS.some(
    (url) => request.url.startsWith(url) || request.url.endsWith(url)
  );
  const sessionClear = sessionStorageCache.some((url) =>
    request.url.includes(url)
  );
  console.log(sessionClear)
  if (!sessionClear) {
    console.log('url', request.url)
    sessionStorage.removeItem('item');
  }
  if (!isVendorApi) {
    // Retrieve access token
    const token = authService.token();

    // Add Authorization header if token exists
    if (!request.url.includes('auth/refresh') && token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
        body: request.body
          ? { data: encryptionService.encrypt(request.body) }
          : request.body,
      });
    }
  }
  return next(request).pipe(
    map((event: HttpEvent<any>) => {
      // Decrypt response only if it's not from a vendor API
      if (!isVendorApi && event instanceof HttpResponse && event.body?.data) {
        console.log('inside', encryptionService.decrypt(event.body.data));
        event = event.clone({
          body: encryptionService.decrypt(event.body.data),
        });
      }
      return event;
    }),
    catchError((error: HttpErrorResponse) => {
      let errorMessage = '';
      let errorObj: any;

      if (error.error instanceof ErrorEvent) {
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // Server-side error handling
        switch (error.status) {
          case 0:
            errorMessage = 'Something went wrong.Please contact tech support';
            showError(messageService, errorMessage);
            break;
          case 400:
            errorMessage = `Bad Request: ${error.message}`;
            errorObj = errorMessage;
            handleValidationErrors(messageService, error);
            break;
          case 401:
            return handle401Error(authService, request, next);
          case 403:
            errorMessage =
              'You do not have permission to this resource. Contact tech support';
            showError(messageService, errorMessage);
            errorObj = errorMessage;
            break;
          case 404:
            errorMessage = `Not Found: ${error.message}`;
            errorObj = errorMessage;
            handleNotFoundError(messageService, error);
            break;
          case 409:
            errorMessage = `${error.error.message}`;
            showError(messageService, errorMessage);
            errorObj = errorMessage;
            break;
          case 422:
            errorMessage = `Unprocessable Entity: ${error.error.message}`;
            showError(messageService, errorMessage);
            errorObj = errorMessage;
            break;
          case 500:
            handleServerError(messageService, error);
            break;
          default:
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            showError(messageService, errorMessage);
            errorObj = errorMessage;
            break;
        }
      }

      return throwError(() => ({
        status: error.status,
        message: errorMessage,
        error: errorObj,
      }));
    }),
    finalize(() => {
      commonService.hide();
    })
  );
};

// Helper methods
const showError = (messageService: MessageService, message: string) => {
  messageService.add({
    severity: 'error',
    summary: 'Error',
    detail: message,
  });
};

const showWarn = (messageService: MessageService, message: string) => {
  messageService.add({
    severity: 'warn',
    summary: 'Warning',
    detail: message,
  });
};

const handleValidationErrors = (
  messageService: MessageService,
  error: HttpErrorResponse
) => {
  if (error?.error?.errors) {
    const errorObj = error.error.errors;
    for (const key in errorObj) {
      if (Object.hasOwn(errorObj, key)) {
        showWarn(messageService, String(errorObj[key]));
      }
    }
  }
};

const handleNotFoundError = (
  messageService: MessageService,
  error: HttpErrorResponse
) => {
  const errorMessage =
    error?.error?.msg || error?.error?.message || 'Resource not found';
  showError(messageService, errorMessage);
};

const handleServerError = (
  messageService: MessageService,
  error: HttpErrorResponse
) => {
  const errorMessage = 'Something went wrong.Please contact tech support';
  showError(messageService, errorMessage);
};

const handle401Error = (
  authService: AuthService,
  request: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const refreshToken = authService.rtoken();

  if (!refreshToken) {
    return throwError(() => 'Refresh token not available');
  }

  return authService.refreshToken({ refreshToken }).pipe(
    switchMap((response: any) => {
      sessionStorage.setItem('authToken', response.data.accessToken);
      sessionStorage.setItem('refreshToken', response.data.refreshToken);
      authService.token.set(response.data.accessToken);
      authService.rtoken.set(response.data.refreshToken);
      return next(
        request.clone({
          setHeaders: {
            Authorization: `Bearer ${response.data.accessToken}`,
          },
        })
      );
    }),
    catchError((err) => {
      authService.logOut();
      return throwError(() => err);
    })
  );
};
