import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';
import { BadgeModule } from 'primeng/badge';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { LoginSignupComponent } from '../login-signup/login-signup/login-signup.component';
import { AuthService } from '../../services/auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-page-layout',
  standalone: true,
  imports: [
    ButtonModule,
    InputTextModule,
    InputGroupModule,
    BadgeModule,
    OverlayPanelModule,
    FontAwesomeModule,
    RouterLink,
    RouterOutlet,
    ToastModule,
  ],
  providers: [DialogService, MessageService],
  templateUrl: './page-layout.component.html',
  styleUrl: './page-layout.component.scss',
})
export class PageLayoutComponent {
  icons = { faEnvelopeOpen };
  cartAmount = signal(3);
  helplineNo = signal('+91 999 xxxx 999');
  location = signal('11/6-B, Shanti Chamber Pusa Road, New Delhi-05');
  contactNo = signal('+918050029004');
  contactNoLink = computed(() => 'tel:' + this.contactNo());
  contactMail = signal('support@smccoins.com');
  contactMailLink = computed(() => 'mailto:' + this.contactMail());
  ref: DynamicDialogRef | undefined;
  isVisible = computed(() => this.authService.user() != null);

  constructor(
    public dialogService: DialogService,
    private router: Router,
    private authService: AuthService
  ) {}

  showDialog() {
    this.ref = this.dialogService.open(LoginSignupComponent, {
      header: 'Login/SignUp',
    });
  }

  navigationToProfile(): void {
    this.router.navigate(['/user/profile']);
  }

  authenticateRoute(): string {
    if (this.isVisible()) {
      return '/user/dashboard';
    }
    return '/home';
  }
}
