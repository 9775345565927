import {
  Component,
  computed,
  HostListener,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';
import { BadgeModule } from 'primeng/badge';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { LoginSignupComponent } from '../login-signup/login-signup/login-signup.component';
import { AuthService } from '../../services/auth.service';
import { MessageService } from 'primeng/api';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { CartViewComponent } from '../cart-view/cart-view.component';
import { SidebarModule } from 'primeng/sidebar';
import { SIPComponent, SipService } from '../../services/sip.service';
import { CartService } from '../../services/product/cart.service';
import { MastersService } from '../../services/masters.service';
import { AvatarModule } from 'primeng/avatar';

@Component({
  selector: 'app-page-layout',
  standalone: true,
  imports: [
    ButtonModule,
    InputTextModule,
    InputGroupModule,
    BadgeModule,
    OverlayPanelModule,
    FontAwesomeModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    ToastModule,
    ButtonGroupModule,
    SidebarModule,
    CartViewComponent,
    AvatarModule,
  ],
  providers: [DialogService],
  templateUrl: './page-layout.component.html',
  styleUrl: './page-layout.component.scss',
})
export class PageLayoutComponent {
  icons = { faEnvelopeOpen };
  get cartAmount() {
    if (this.cartService.cart.length == 0) {
      return 0;
    }
    var sum = this.cartService.cart.map((x) => x.count).reduce((a, b) => a + b);
    return sum;
  }
  helplineNo = signal('');
  location = signal('');
  contactNo = signal('');
  contactNoLink = computed(() => 'tel:' + this.contactNo());
  contactMail = signal('');
  contactMailLink = computed(() => 'mailto:' + this.contactMail());
  ref: DynamicDialogRef | undefined;
  isVisible = computed(() => this.authService.user() != null);
  showSidebar = false;
  windowWidth!: number;
  isAdmin = computed(() => this.authService.isAdmin());
  username = computed(() => this.authService.user()?.fullName ?? 'User');

  constructor(
    public dialogService: DialogService,
    private router: Router,
    private readonly masterService: MastersService,
    private authService: AuthService,
    private sipService: SipService,
    private cartService: CartService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.onResize(window.innerWidth);

    this.masterService
      .getContent('location')
      .subscribe((val: any) => this.location.set(val));
    this.masterService.getContent('contactNumber').subscribe((val: any) => {
      this.contactNo.set(val);
      this.helplineNo.set(val);
    });

    this.masterService
      .getContent('email')
      .subscribe((val: any) => this.contactMail.set(val));
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(windowWidth: number) {
    this.windowWidth = windowWidth;
  }

  showDialog() {
    this.ref = this.dialogService.open(LoginSignupComponent, {
      header: 'Login/SignUp',
    });
  }

  navigationToProfile(): void {
    this.router.navigate(['/user/profile']);
  }

  authenticateRoute(): string {
    if (this.isVisible()) {
      return '/user/dashboard';
    }
    return '/home';
  }

  signOutUser() {
    this.authService.logOut();
  }

  clearCart() {
    this.cartService.clear();
  }

  updateStage() {
    this.sipService.changeState(SIPComponent.Home);
    this.sipService.setSipId(null)
  }

  getCurrentPageRoute() {
    return this.router.url;
  }
}
