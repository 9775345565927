import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/others/login/login.component').then(
        (m) => m.LoginComponent
      ),
  },
  {
    path: 'product',
    loadComponent: () =>
      import('./pages/product/products/products.component').then(
        (m) => m.ProductsComponent
      ),
  },
  {
    path: 'buy-gold',
    loadComponent: () =>
      import('./pages/buy-gold/buy-gold.component').then(
        (m) => m.BuyGoldComponent
      ),
  },
  {
    path: 'sell-gold',
    loadComponent: () =>
      import('./pages/sell-gold/sell-gold.component').then(
        (m) => m.SellGoldComponent
      ),
  },
  {
    path: 'info/:info',
    loadComponent: () =>
      import('./pages/info/info.component').then((m) => m.InfoComponent),
  },
  {
    path: 'user/profile',
    loadComponent: () =>
      import('./pages/user/profile-view/profile-view.component').then(
        (m) => m.ProfileViewComponent
      ),
  },
  {
    path: 'user/dashboard',
    loadComponent: () =>
      import('./pages/user/dashboard/dashboard.component').then(
        (m) => m.DashboardComponent
      ),
  },
  {
    path: 'error',
    loadComponent: () =>
      import('./pages/others/error/error.component').then(
        (m) => m.ErrorComponent
      ),
  },
  {
    path: 'transaction/status',
    loadComponent: () =>
      import('./pages/others/page-status/page-status.component').then(
        (m) => m.PageStatusComponent
      ),
  },
  {
    path: 'kyc/pan',
    loadComponent: () =>
      import('./pages/pan-verify/pan-verify.component').then(
        (m) => m.PanVerifyComponent
      ),
  },
  {
    path: 'transactions',
    loadComponent: () =>
      import('./pages/transaction/transaction.component').then(
        (m) => m.TransactionComponent
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];
