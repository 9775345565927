import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root',
})
export class OtpService {
  private apiUrl = environment.digigoldbe;

  constructor(private http: HttpClient, private user: UsersService) {}

  sendOtp(phoneNumber: string) {
    return this.http.get(`${this.apiUrl}/otp`, {
      params: { phone: phoneNumber },
    });
  }

  verifyOtp(phoneNumber: string, otp: string) {
    return this.http
      .get<any>(`${this.apiUrl}/otp/verify-otp`, {
        params: { phone: phoneNumber, otp: otp },
      })
      .pipe(
        catchError((error) => {
          console.log('eroror', error);
          return of(error.error);
        }),
        switchMap((res) => {
          if (res.statusCode === 200) {
            return this.user.createUser(phoneNumber);
          } else {
            return of(res);
          }
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }
}
